<template>
  <div class="dimbox">
    <div class="mHeader">
      <p>계정 삭제</p>
    </div>
    <div class="mBody">
      <div class="registerBox">
        <div class="flexL">
          <p>이메일</p>
          <span>{{ userInfo.email }}</span>
        </div>
        <div class="flexL">
          <p>이름</p>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="flexL">
          <p>팀명</p>
          <span>{{ userInfo.team ? userInfo.team.teamName : "" }}</span>
        </div>
        <div class="flexL">
          <p>휴대폰</p>
          <span>{{ getMask(userInfo.mobileNo) }}</span>
        </div>
        <div class="flexL">
          <p>삭제사유</p>
          <input type="text" v-model="dropReason" />
        </div>
      </div>
      <div class="buttonWrap">
        <button @click="submit()" class="delete">삭제</button>
        <button @click="close" class="sub">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
import { deleteUser } from "@/api/index";
import { format } from "@/mixins/format";
export default {
  props: ["userInfo"],
  mixins: [format],
  data() {
    return {
      email: "",
      name: "",
      mobileNo: "",
      dropReason: "",
      // userInfo: {},
    };
  },

  activated() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if (this.dropReason == "") {
        return alert("삭제사유를 입력해 주세요.");
      }
      let result = confirm("계정을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      let data = {
        dropReason: this.dropReason,
        userId: this.userInfo._id,
      };
      deleteUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("계정 삭제가 완료 되었습니다.");
          this.$emit("getUserList");
          this.$emit("close");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
