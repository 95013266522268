<template>
  <div class="dimbox">
    <div class="mHeader">
      <p>신규계정 생성</p>
    </div>
    <div class="mBody">
      <div class="registerBox">
        <div class="flexL">
          <p>이메일</p>
          <input type="text" v-model="email" />
        </div>
        <div class="flexL">
          <p>이름</p>
          <input type="text" v-model="name" />
        </div>
        <div class="flexL">
          <p>팀</p>
          <select v-model="teamId">
            <option
              v-for="item in options"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="flexL">
          <p>휴대전화</p>
          <input type="text" v-model="mobileNo" />
        </div>
      </div>
      <div class="buttonWrap">
        <button class="main" @click="submit">생성</button>
        <button @click="close" class="sub">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { createUser, fetchTeamList } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      email: "",
      name: "",
      teamId: "",
      mobileNo: "",
      options: [],
    };
  },
  mounted() {
    this.getTeamList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getTeamList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
      };
      fetchTeamList(params).then((res) => {
        if (res.data.status == 200) {
          this.teamList = res.data.data;
          this.options = res.data.data.map((res) => {
            return { value: res._id, label: res.teamName };
          });
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        }
      });
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 올바르지 않습니다.");
      } else if (this.email.length > 32) {
        return alert("글자수는 32자리를 초과할 수 없습니다.");
      } else if (this.name == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.teamName == "") {
        return alert("팀을 선택해 주세요.");
      } else if (this.mobileNo == "") {
        return alert("휴대전화를 입력해 주세요.");
      }
      let data = {
        email: this.email,
        name: this.name,
        team: this.teamId,
        mobileNo: this.mobileNo,
      };
      createUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("신규계정 생성이 완료 되었습니다.");
          this.$emit("getUserList");
          this.$emit("close");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
