<template>
  <div class="contents">
    <div class="title flexB">
      <h2>계정정보 관리</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box one filter">
      <div class="flex filtering"></div>
      <div class="flex searching">
        <select v-model="inputMode">
          <option disabled selected value>선택</option>
          <option value="name">이름</option>
          <option value="team">팀명</option>
          <option value="email">이메일</option>
          <option value="mobileNo">휴대폰</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
        <div class="buttons">
          <button @click="search" class="search">
            <span class="material-icons-outlined"> search </span>
          </button>
          <button @click="reset" class="reset">
            <span class="material-icons-outlined"> refresh </span>
          </button>
        </div>
      </div>
    </div>
    <div class="box table one">
      <div class="top flexB">
        <div class="tit flex">
          <h2 class="bold">DAS 관리자 리스트</h2>
          <p>
            <span class="count">{{ total }}</span
            >개의 검색결과가 있습니다.
          </p>
        </div>
        <div class="buttons">
          <el-button class="" @click="handleDownload()" :loading="downDisabled">
            <i class="el-icon-download" />
            전체 다운로드(.xlsx)
          </el-button>
          <button class="registerBtn" @click="handleJoinModal()">
            신규계정 생성
          </button>
        </div>
      </div>
      <el-table
        class="styled-table"
        :data="userList"
        @sort-change="sort"
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
        row-key="_id"
        resizable
        border
      >
        <el-table-column label="번호" prop="dataNum" min-width="10">
          <template slot-scope="scope">{{ scope.row.dataNum }}</template>
        </el-table-column>
        <el-table-column label="아이디" prop="email" min-width="17">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>
        <el-table-column label="이름" prop="name" min-width="10" sortable="custom">
          <template slot-scope="scope">{{ scope.row.dropedAt ? "탈퇴계정" : scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="팀명" prop="team.teamName" min-width="14" sortable="custom">
          <template slot-scope="scope">{{ scope.row.team ? scope.row.team.teamName : "" }}</template>
        </el-table-column>
        <el-table-column label="휴대폰" prop="mobileNo" min-width="13">
          <template slot-scope="scope">{{ getMask(scope.row.mobileNo) }}</template>
        </el-table-column>
        <el-table-column label="최근 접속일자" prop="logedInAt" min-width="14" sortable="custom">
          <template slot-scope="scope">
            {{
              scope.row.logedInAt
                ? moment(scope.row.logedInAt).format("YYYY.MM.DD HH:mm:ss")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="가입일" prop="createdAt" min-width="11" sortable="custom">
          <template slot-scope="scope">{{ moment(scope.row.createdAt).format("YYYY.MM.DD") }}</template>
        </el-table-column>
        <el-table-column label="상태" prop="dropReason" min-width="11">
          <template slot-scope="scope">
            {{ scope.row.dropedAt ? scope.row.dropReason : "" }}
            <button
              v-if="!scope.row.dropedAt"
              class="delete"
              @click="handleDeleteModal(scope.row)"
            >
              <span class="material-icons-outlined"> delete </span>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="visible">
        <JoinAccount
          v-if="joinVisible"
          @getUserList="getUserList"
          @close="close"
        />
        <Withdrawal
          v-if="deleteVisible"
          @getUserList="getUserList"
          :userInfo="userInfo"
          @close="close"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { deleteUser, fetchUserList } from "@/api/index";
import { format } from "@/mixins/format";
import JoinAccount from "@/components/pop/JoinAccount.vue";
import Withdrawal from "@/components/pop/Withdrawal.vue";
import { mapState } from "vuex";
export default {
  components: { Withdrawal, JoinAccount },
  mixins: [format],
  name: "UserManage",
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      currentComponent: "",
      visible: false,
      joinVisible: false,
      deleteVisible: false,
      userId: "",
      userInfo: {},
      sortOption: {
        prop: null,
        order: null,
      },
      downDisabled: false,
    };
  },
  computed: {
    ...mapState(["name", "team", "_id", "isAdmin"]),
  },
  mounted() {
    this.$store.dispatch("SET_NAVBAR", "1-1");
    setTimeout(() => {
      if (this.isAdmin == false) {
        this.$router.push("/");
      }
    }, 500);
    this.getUserList();
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "1-1");
    setTimeout(() => {
      if (this.isAdmin == false) {
        this.$router.push("/");
      }
    }, 500);
    this.getUserList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDeleteUser(id) {
      let result = confirm("회원을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteUser(id).then((res) => {
        if (res.data.status == 200) {
          this.getUserList();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.userList = [];
      this.total = 0;
      this.currentPage = 1;
      this.sortOption = {
        prop: null,
        order: null,
      };
      this.getUserList();
    },
    sort(event) {
      this.sortOption = event;
      this.getUserList();
    },
    search() {
      if (this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        sortBy: this.sortOption.prop,
        sortOrder: this.sortOption.order,
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    handleJoinModal() {
      this.visible = true;
      this.joinVisible = true;
    },
    handleDeleteModal(userInfo) {
      this.userInfo = userInfo;
      this.visible = true;
      this.deleteVisible = true;
    },
    close() {
      this.userInfo = {};
      this.visible = false;
      this.joinVisible = false;
      this.deleteVisible = false;
    },
    async handleDownload() {
      this.downDisabled = true;
      let params = {
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        sortBy: this.sortOption.prop,
        sortOrder: this.sortOption.order,
      };

      try {
        const res = await fetchUserList(params);
        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }
        const data = res.data.data.map(item => ({
          "아이디": item.email,
          "이름": item.dropedAt ? "탈퇴계정" : item.name,
          "팀명": item.team ? item.team.teamName : "",
          "휴대폰": this.getMask(item.mobileNo),
          "최근 접속일자": item.logedInAt ? moment(item.logedInAt).format("YYYY.MM.DD HH:mm:ss") : "",
          "가입일": moment(item.createdAt).format("YYYY.MM.DD"),
          "상태": item.dropedAt ? item.dropReason : "",
        }));
        var excelData = XLSX.utils.json_to_sheet(data); // table id를 넣어주면된다
        var workBook = XLSX.utils.book_new(); // 새 시트 생성
        XLSX.utils.book_append_sheet(workBook, excelData, "DAS 관리자 리스트"); // 시트 명명, 데이터 지정
        XLSX.writeFile(
          workBook,
          `DAS_관리자_리스트_${moment().format("YYYYMMDDHHmm")}.xlsx`
        ); // 엑셀파일 만듬
      } catch (err) {
        return alert(err.message);
      } finally {
        this.downDisabled = false;
      }
    },
  },
};
</script>
